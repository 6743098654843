<template>
  <apexchart type="radialBar" height="390" :options="chartOptions" :series="series"></apexchart>
</template>
<script>
export default {
  components: {
    apexchart: () => import('vue-apexcharts'),
  },
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [
        (this.summary.total / (this.summary.total || 1)) * 100,
        (this.summary.seguridad / (this.summary.total || 1)) * 100,
        (this.summary.medico / (this.summary.total || 1)) * 100,
        (this.summary.proteccion_civil / (this.summary.total || 1)) * 100,
        (this.summary.servicios_publicos / (this.summary.total || 1)) * 100,
      ],
      chartOptions: {
        chart: {
          height: 390,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
            barLabels: {
              enabled: true,
              useSeriesColors: true,
              offsetX: -8,
              fontSize: '16px',
              formatter: (seriesName, opts) => {
                return (
                  seriesName + ':  '
                  + Math.round((opts.w.globals.series[opts.seriesIndex] / 100.0) * this.summary.total)
                );
              },
            },
          },
        },

        colors: ['#70C1B3', '#247BA0', '#FF1654', '#B2DBBF', '#F3FFBD'],
        labels: ['TOTAL', 'Seguridad', 'Médicos', 'P. Civil', 'Serv. Públicos'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
};
</script>
